import React from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';

const RedText = styled.span`
  text-decoration: underline;
  color: #f5222d;
`;

/**
 *
 * Matches anything that follows the pattern of {{custom_field_*}} and has no spaces.
 * Matches: "{{custom_field_county_name}}"
 * Does not match:
 *          "{{custom_county_name}}"
 */
const customFieldRegex = /({{custom_field_\S*?}})/g;

/**
 *
 * Matches anything that follows the pattern of {{some-text-here}} and has no spaces.
 * Matches: "{{user.name}}"
 * Does not match:
 *          "{{user.name} }"
 */
const varsRegex = /({{\S*?}})/g;

/**
 * Matches anything that looks like a var, but has unnecessary characters inside
 * Matches: "{{user name}}"
 *          "{{user.name} }"
 *          "{asd{user.name}}"
 * Does not match:
 *          "{{user.name}"
 *          "{user.name}}"
 */
const brokenVarsRegex = /({[^{]*?{\s*?\S*?\s*?\S*?\s*?}[^}]*?})/g;

/**
 * Matches anything, that looks like a var, but misses a parenthesis on right side
 * Matches missing right parenthesis: {{user.full_name}
 */
const missingRightParenthesisRegexp = /({{[^\s}]*?}([^}]|$))/g;

/**
 * Matches anything, that looks like a var, but misses a parenthesis on left side
 * Matches missing left parenthesis: {user.full_name}}
 */
const missingLeftParenthesisRegexp = /(([^{]|^){[^\s{]*?}})/g;

//matches emoji`s and unicode symbols
export const EmojiRegexp = /\p{Extended_Pictographic}/gu;

//matches anything that looks like a trackable link
//example: {{'this-is-a-link'|trackable}}
const trackableLinksRegexp = /{{'(.*)'|trackable}}/g;

/**
 * All broken vars regexps combined with a '|' pipe, not to call each one separately later.
 */
const combinedBrokenVarsRegexp = new RegExp(
  `${brokenVarsRegex.source}|${missingLeftParenthesisRegexp.source}|${missingRightParenthesisRegexp.source}`,
);

const splitWithRegexp = (text, regexp) =>
  Array.isArray(text) ? text.flatMap(str => str.split(regexp)) : text.split(regexp);

export const generateInterpolatedSegmentsForPreview = (user, campaign, text, limit) => {
  let interpolatedText = text;

  const userVars = {
    'user.email': user.email,
    'user.first_name': user.first_name,
    'user.full_name': `${user.first_name} ${user.last_name}`,
    'user.last_name': user.last_name,
    'user.phone': user.phone,
    'user.team_code': user.supplied_state_abbrev,
  };

  const campaignVars = {
    broadcast_phone_number: campaign.broadcast_number,
    'campaign.description': campaign.description,
    'campaign.join_code': campaign.join_code,
    'campaign.name': campaign.name,
  };

  const contactVars = {
    email: 'jordan@smith.com',
    first_name: 'Jordan',
    full_name: 'Jordan Smith',
    last_name: 'Doe',
    phone: '555-123-4567',
    state_abbrev: 'NC',
  };

  const defaultVars = {
    ...userVars,
    ...campaignVars,
    ...contactVars,
  };

  //split by correct vars
  interpolatedText = interpolatedText.split(varsRegex);

  //split by broken vars
  interpolatedText = splitWithRegexp(interpolatedText, combinedBrokenVarsRegexp);

  //split everything else word-by-word for dividing into segments
  interpolatedText = Array.isArray(interpolatedText)
    ? interpolatedText.flatMap(str => splitInterpolationByWords(str))
    : splitInterpolationByWords(interpolatedText);

  //replace interpolation with vars and add labels to errors
  interpolatedText = Array.isArray(interpolatedText)
    ? interpolatedText
        .map(str => generateVarObject(str, defaultVars))
        .filter(obj => Boolean(obj.text))
    : generateVarObject(interpolatedText);

  return divideIntoSegments(interpolatedText, limit);
};

//if text matches the pattern for interpolation, skip splitting
//else, split it into words
const splitInterpolationByWords = text => {
  if (!varsRegex.test(text) && !combinedBrokenVarsRegexp.test(text)) return text?.split(/(\s)/g);
  else return text;
};

const generateVarObject = (text, defaultVars) => {
  if (trackableLinksRegexp.test(text)) {
    return {
      status: 'bold',
      text: `https://${window.location.host}/t/12345678`,
      variable: text,
    };
  }

  if (customFieldRegex.test(text)) {
    /*
      writing this feature fast for a bug outage and difficult to know actual
      values for whatever custom field was referenced
    */
    const interpolatedVariable = 'Sample Custom Field Value';
    return {
      status: interpolatedVariable ? 'bold' : 'unknown',
      text: interpolatedVariable ?? text,
      variable: text,
    };
  }

  if (varsRegex.test(text)) {
    const interpolatedVariable = defaultVars[text.slice(2, -2)];
    return {
      status: interpolatedVariable ? 'bold' : 'unknown',
      text: interpolatedVariable ?? text,
      variable: text,
    };
  }

  return {
    status: combinedBrokenVarsRegexp.test(text) ? 'error' : 'regular',
    text,
  };
};

//divide text into segments no more than 160 characters
const divideIntoSegments = (textArr, limit) => {
  if (!Array.isArray(textArr)) return [textArr];

  const result = [];
  let current = 0;
  textArr.forEach(elem => {
    if (current + elem.text.length > limit) {
      current = elem.text.length;
      result.push([elem]);
    } else {
      current = current + elem.text.length;
      result[result.length - 1] ? result[result.length - 1].push(elem) : result.push([elem]);
    }
  });

  return result;
};

export const styleSegments = segment => {
  switch (segment.status) {
    case 'regular':
      return segment.text;
    case 'error':
      return (
        <RedText>
          <Tooltip title="This variable is not formatted correctly. Make sure you have an even number of brackets on each side and no extra spaces, and try again.">
            {segment.text}
          </Tooltip>
        </RedText>
      );
    case 'unknown':
      return (
        <RedText>
          <Tooltip title="This variable does not exist. Try checking the spelling, or create the variable in your campaign's settings.">
            {segment.text}
          </Tooltip>
        </RedText>
      );
    case 'bold':
      return (
        <Tooltip title={segment.variable}>
          <u>
            <strong>{segment.text}</strong>
          </u>
        </Tooltip>
      );
    default:
      return segment.text;
  }
};

export const verifyDynamicVars = script =>
  !script
    ?.split(varsRegex)
    ?.filter(word => !varsRegex.test(word))
    ?.some(word => combinedBrokenVarsRegexp.test(word));
