import * as yup from 'yup';

import { isTurfActivity } from '@admin/components/ActivitiesPage/ActivityForm/components/TurfsActivityHelpers/utils';
import { isTextingActivity } from '@admin/components/ActivitiesPage/utils';
import { verifyDynamicVars } from '@admin/components/ActivitiesPage/ActivityScripts/PreviewModal/utils';

// source: modified version of https://www.regextester.com/93652
const urlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

const shouldValidateScripts = (type, modes_permitted) => {
  const turfActivity = isTurfActivity(type);
  if (turfActivity && modes_permitted && modes_permitted.length !== 0) return true;

  if (isTextingActivity(type) && !turfActivity) return true;

  return false;
};

const ActivityScriptsAttributesSchema = yup.array().when(['type', 'modes_permitted'], {
  is: shouldValidateScripts,

  then: yup.array().of(
    yup
      .object()
      .shape({
        name: yup.string().required('Script Name is required!'),
        script: yup
          .string()
          .required('Script Message is required!')
          .test(
            'has-valid-vars',
            'Script contains invalid dynamic variable. Please double check the script',
            verifyDynamicVars,
          ),
      })
      .required('Activity scripts are required.'),
  ),
});

const ActivityIncentivesAttributesSchema = yup.array().of(
  yup.object().shape({
    name: yup.string().required('Name is required!'),
  }),
);

const ActivitySchema = yup.object().shape({
  activity_custom_fields_attributes: yup.array(),
  activity_incentives_attributes: ActivityIncentivesAttributesSchema,
  activity_scripts_attributes: ActivityScriptsAttributesSchema,
  activity_tags_attributes: yup.array(),
  automated_text_activity_id: yup.string().when('automated_texting_enabled', {
    is: value => !!value,
    otherwise: yup.string().nullable(),
    then: yup.string().required('Texting Automation is required!').nullable(),
  }),
  confirmRequirements: yup.bool().when('type', {
    is: value => value === 'TextAListActivity',
    otherwise: yup.bool().nullable(),
    then: yup.bool().oneOf([true], 'Confirmation is required!').required(),
  }),
  contact_list_id: yup.string().when('type', {
    is: value =>
      value === 'TextAListActivity' ||
      value === 'IdVotersActivity' ||
      value === 'DialerActivity' ||
      value === 'AssignedCanvassingActivity',
    then: yup.string().required('Contact list is required!').nullable(),
    otherwise: yup.string().nullable(),
  }),
  description: yup
    .string()
    .max(300, "Description can't be longer than 300 characters.")
    .required('Action Description is required!'),
  go_to_link: yup.string().when('type', {
    is: value => value === 'WebActivity',
    then: yup
      .string()
      .matches(urlRegex, {
        excludeEmptyString: true,
        message: 'Link must be a valid URL that starts with http:// or https://.',
      })
      .required('Link is required!')
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
  share_link: yup.string().when('type', {
    is: value => value === 'ShareActivity',
    then: yup
      .string()
      .matches(urlRegex, {
        excludeEmptyString: true,
        message: 'Link must be a valid URL that starts with http:// or https://.',
      })
      .required('Link is required!')
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
  share_text: yup.string().when('type', {
    is: value => value === 'ShareActivity',
    then: yup.string().required('Must be valid text.').nullable(),
    otherwise: yup.string().nullable(),
  }),
  title: yup
    .string()
    .max(100, "Title can't be longer than 100 characters.")
    .required('Action Title is required!'),
  type: yup
    .string()
    .oneOf([
      'DialerActivity',
      'TextAFriendActivity',
      'TextAListActivity',
      'ShareActivity',
      'FormActivity',
      'WebActivity',
      'NewsActivity',
      'TestimonialActivity',
      'IdVotersActivity',
      'AssignedCanvassingActivity',
      'BroadcastActivity',
      'RegistrationActivity',
    ]),
});

const CampaignSchema = tab => {
  return yup.object().shape({
    broadcast_closed_auto_reply: yup.string().when([], {
      is: () => tab === 'broadcast',
      otherwise: yup.string().notRequired().nullable(),
      then: yup.string().notRequired().nullable(),
    }),

    broadcast_end_hour: yup.string().when([], {
      is: () => tab === 'broadcast',
      otherwise: yup.string().notRequired(),
      then: yup.string().required('End time is required.'),
    }),

    broadcast_start_hour: yup.string().when([], {
      is: () => tab === 'broadcast',
      otherwise: yup.string().notRequired(),
      then: yup.string().required('Start time is required.'),
    }),

    campaign_localization: yup.object().when('showSpanishBrandInfo', {
      is: true,
      otherwise: yup.object().notRequired().nullable(),
      then: yup.object().shape({
        description: yup
          .string()
          .max(1000, "Description can't be longer than 1000 characters.")
          .required('Description is required!'),
        slogan: yup
          .string()
          .max(100, "Slogan can't be longer than 100 characters.")
          .required('Slogan is required!'),
      }),
    }),

    description: yup.string().when([], {
      is: () => tab === 'settings',
      otherwise: yup.string().notRequired(),
      then: yup
        .string()
        .max(1000, "Description can't be longer than 1000 characters.")
        .required('Description is required!'),
    }),
    terms: yup.string().when('showTermsAndConditions', {
      is: true,
      then: yup.string().required('Terms and Conditions are required').nullable(),
    }),

    join_terms: yup.string().when('showPrivacyPolicy', {
      is: true,
      then: yup.string().required('Privacy Policy is required').nullable(),
    }),

    outreach_end_hour: yup.string().when([], {
      is: () => tab === 'p2p',
      otherwise: yup.string().notRequired(),
      then: yup.string().required('End time is required.'),
    }),

    outreach_preferred_area_codes: yup.array().when([], {
      is: () => tab === 'p2p',
      then: yup
        .array()
        .of(yup.string().matches(/^\d{3}$/, 'Area code must be 3 digits'))
        .notRequired()
        .nullable(),
    }),

    outreach_start_hour: yup.string().when([], {
      is: () => tab === 'p2p',
      otherwise: yup.string().notRequired(),
      then: yup.string().required('Start time is required.'),
    }),

    outreach_time_zone: yup.string().when([], {
      is: () => tab === 'p2p' || tab === 'broadcast',
      otherwise: yup.string().notRequired(),
      then: yup.string().required('Time Zone is required.'),
    }),

    preferred_broadcast_area_codes: yup.string().when([], {
      is: () => tab === 'broadcast',
      then: yup
        .string()
        .matches(/^\d{3}(,{1}\s?\d{3})*$/, {
          excludeEmptyString: true,
          message: 'Area codes separated by commas only',
        })
        .nullable(),
    }),

    privacy_link: yup.string().when([], {
      is: () => tab === 'legal',
      then: yup
        .string()
        .matches(urlRegex, {
          excludeEmptyString: true,
          message:
            'You must enter a valid URL that starts with http:// or https:// for your privacy text link.',
        })
        .nullable(),
    }),

    slogan: yup.string().when([], {
      is: () => tab === 'settings',
      otherwise: yup.string().notRequired(),
      then: yup
        .string()
        .max(100, "Slogan can't be longer than 100 characters.")
        .required('Slogan is required!'),
    }),

    terms_link: yup.string().when([], {
      is: () => tab === 'legal',
      then: yup
        .string()
        .matches(urlRegex, {
          excludeEmptyString: true,
          message:
            'You must enter a valid URL that starts with http:// or https:// for your terms and conditions link.',
        })
        .nullable(),
    }),

    website_url: yup.string().when([], {
      is: () => tab === 'settings',
      then: yup
        .string()
        .matches(urlRegex, {
          excludeEmptyString: true,
          message:
            'You must enter a valid URL that starts with http:// or https:// for your campaign website.',
        })
        .nullable(),
    }),
  });
};

const UserReportSchema = yup.object().shape({
  email: yup.string(),
  first_name: yup.string(),
  last_name: yup.string(),
  phone: yup.string(),
});

const ScriptsAttributesSchema = yup.array().of(
  yup
    .object()
    .shape({
      keyword: yup.string().nullable(),
      name: yup.string().required('Script name is required!'),
      script: yup
        .string()
        .required('Script Message is required!')
        .test(
          'has-valid-vars',
          'Script contains invalid dynamic variable. Please double check the script',
          verifyDynamicVars,
        ),
    })
    .required('Scripts are required.'),
);

const OptInFormSchema = yup.object().shape({
  name: yup
    .string()
    .max(100, "Name can't be longer than 100 characters.")
    .required('Name is required!'),
  title: yup
    .string()
    .max(100, "Form title can't be longer than 100 characters.")
    .required('Form title is required!'),
  subtitle: yup.string().max(500, "Subtitle can't be longer than 500 characters."),
  join_keyword: yup
    .string()
    .max(50, "Join keyword can't be longer than 50 characters.")
    .required('Join keyword is required!'),
  button_text: yup.string().max(50, "Button text can't be longer than 50 characters."),
  initial_response_text: yup.string().required('Initial response text is required!'),
  scripts_attributes: ScriptsAttributesSchema,
});

const BroadcastFormSchema = yup.object().shape({
  activity_scripts_attributes: ScriptsAttributesSchema,
  confirmRequirements: yup.bool().oneOf([true], 'Confirmation is required!').required(),
  contact_list_id: yup.string().when('forUsers', {
    is: value => !value,
    otherwise: yup.string().nullable(),
    then: yup.string().required('Contact list is required!').nullable(),
  }),
  handlerUserListId: yup.number().nullable().required('Responder List is required!'),
  title: yup
    .string()
    .max(100, "Name can't be longer than 100 characters.")
    .required('Broadcast Name is required!'),
  user_list_id: yup.string().when('forUsers', {
    is: value => !!value,
    otherwise: yup.string().nullable(),
    then: yup.string().required('User list is required!').nullable(),
  }),
});

const AutomatedTextingFormSchema = yup.object().shape({
  activity_scripts_attributes: yup.array().of(
    yup
      .object()
      .shape({
        keyword: yup.string().nullable(),
        name: yup.string().when('script_type', {
          is: scriptType => scriptType === 'response',
          otherwise: yup.string().nullable(),
          then: yup.string().required('Script name is required!'),
        }),
        script: yup
          .string()
          .required('Script Message is required!')
          .test(
            'has-valid-vars',
            'Script contains invalid dynamic variable. Please double check the script',
            verifyDynamicVars,
          ),
      })
      .required('Scripts are required.'),
  ),
  handlerUserListId: yup.number().nullable().required('Responder List is required!'),
  title: yup
    .string()
    .max(100, "Name can't be longer than 100 characters.")
    .required('Texting Automation Title is required!'),
});

export {
  ActivitySchema,
  ActivityScriptsAttributesSchema,
  ActivityIncentivesAttributesSchema,
  AutomatedTextingFormSchema,
  BroadcastFormSchema,
  CampaignSchema,
  OptInFormSchema,
  ScriptsAttributesSchema,
  UserReportSchema,
  urlRegex,
};
