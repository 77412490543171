export const modes = {
  sms: {
    id: 0,
    label: 'Text',
  },
  email: { id: 1, label: 'Email' },
  phone: { id: 2, label: 'Phone Call' },
  social: { id: 3, label: 'Message' },
};

export const setModesPermitted = (values, mode, setFieldValue, deleteScript) => {
  const scripts = values.activity_scripts_attributes;

  const shouldDelete = deleteScript && scripts.filter(script => script.mode === mode).length === 1;

  if (!values.modes_permitted.includes(modes[mode]?.id)) {
    modes[mode]?.id &&
      setFieldValue('modes_permitted', [...values.modes_permitted, modes[mode].id]);
  } else if (shouldDelete) {
    setFieldValue(
      'modes_permitted',
      values.modes_permitted.filter(item => item !== modes[mode].id),
    );
  }
};

export const shouldRenderScripts = values => {
  if (
    isTurfActivity(values.type) &&
    values.modes_permitted &&
    values.modes_permitted?.length !== 0
  ) {
    return true;
  }

  return values.type !== 'IdVotersActivity' && values.type !== 'AssignedCanvassingActivity';
};

export const isTurfActivity = type =>
  type === 'IdVotersActivity' ||
  type === 'AssignedCanvassingActivity' ||
  type === 'assigned_list_canvassing' ||
  type === 'shared_list_canvassing' ||
  type === 'canvassing' ||
  type === 'List Canvassing' ||
  type === 'Open Canvassing' ||
  type === 'ReportActivity';
